<template>
  <div class="y-modal">
    <div class="y-loading" v-if="!isLoadingTxt">
      <span class="shape shape-1"></span>
      <span class="shape shape-2"></span>
      <span class="shape shape-3"></span>
      <span class="shape shape-4"></span>
    </div>
    <div class="y-loading1" v-else>
      <div class="load-icon">
        <img :src="loadingImage" alt="">
      </div>
      <div class="load-text">{{loadingTxt}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'YLoading',
  data() {
    return {
    };
  },
  props: {
    isLoadingTxt: {
      type: Boolean,
      default: false,
    },
    loadingTxt: {
      type: String,
      default: '',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    loadingImage: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
  },
};
</script>
<style scoped lang="scss">
.y-modal {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-color: transparent;
  z-index: 800;
}
.y-loading {
  width: 50px;
  height: 50px;
  position: absolute;
  animation: Animation4Container 1s ease infinite;
  transform: rotate(45deg);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  .shape {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
  }
  .shape-1 {
    background-color: #1875E5;
    animation: Animation4Shape1 0.3s ease infinite alternate;
    left: 0;
  }
  .shape-2 {
    background-color: #C5523F;
    animation: Animation4Shape2 0.3s ease infinite 0.3s alternate;
    right: 0;
  }
  .shape-3 {
    background-color: #499255;
    animation: Animation4Shape3 0.3s ease infinite 0.3s alternate;
    bottom: 0;
  }
  .shape-4 {
    background-color: #F2B736;
    animation: Animation4Shape4 0.3s ease infinite alternate;
    bottom: 0;
    right: 0;
  }
}
.y-loading1 {
  width: 199px;
  height: 199px;
  background: rgba(51,51,51,0.9);
  border-radius: 12px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  .load-icon {
    width: 66px;
    margin: auto;
    position: relative;
    padding-top: 27px;
    img {
      max-width: 100%;;
      animation: rotation infinite .75s linear;
    }
  }
  .load-text {
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 33px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@keyframes Animation4Shape1 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    transform: translate(5px, 5px);
  }
}
@keyframes Animation4Shape2 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-5px, 5px);
  }
}
@keyframes Animation4Shape3 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    transform: translate(5px, -5px);
  }
}
@keyframes Animation4Shape4 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-5px, -5px);
  }
}
@-webkit-keyframes Animation4Container {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotation {
    0% {
      transform: rotate(360deg);
    }

    to {
      transform: rotate(0deg);
    }
  }
</style>
