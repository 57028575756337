/* eslint-disable no-use-before-define */
export function getUrlParam() {
  const reg = new RegExp('(^|\\?|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.href.match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}

export function getAppStatus() {
  let cuserId = getUrlParam('cuserId') || '';
  cuserId && window.sessionStorage.setItem('cuserId', cuserId);
  const appStatus = {
    cuserId: cuserId || window.sessionStorage.getItem('cuserId'),
    source: '13000',
    releaseVersion: '4.0.0',
  };
  console.log(/i(os|phone|pad)/i.test(navigator.userAgent));
  try {
    if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
      // IOS
      try {
        appStatus.cuserId = window.ios.jzUserId();
        appStatus.source = window.ios.jzSource();
        appStatus.releaseVersion = window.ios.appVersion();
      } catch (e) {
        console.log(e);
      }
      appStatus.isHuawei = false;
    } else if (/android/i.test(navigator.userAgent)) {
      // android
      appStatus.cuserId = window.android.jzUserId();
      appStatus.source = window.android.jzSource();
      appStatus.releaseVersion = window.android.jzAppVersion();
      appStatus.appId = window.android.jzAppId();
      appStatus.isHuawei = window.android.isHuaweiPhone();
    }
  } catch (e) {
    // TODO handle the exception
    console.log('获取客户端方法异常:', e);
  }
  return appStatus;
}

export function setupWebViewJavascriptBridge(callback) {
  if (window.WVJBCallbacks) return window.WVJBCallbacks.push(callback);
  window.WVJBCallbacks = [callback];
  let WVJBIframe = document.createElement('iframe');
  WVJBIframe.style.display = 'none';
  WVJBIframe.src = 'https://__bridge_loaded__';
  document.documentElement.appendChild(WVJBIframe);
  setTimeout(() => {
    document.documentElement.removeChild(WVJBIframe);
    console.log(121212);
  }, 0);
}
/**
 * 调用 APP 事件
 * @param {*} name 调用的APP方法名
 * @param {*} param 发送的参数
 * @param {*} callback 发送的回调函数（在需要调入的地方传入）
 */
export function callAppHandler(name, param, callback) {
  setupWebViewJavascriptBridge((bridge) => {
    bridge.callHandler(name, param, callback);
  });
}

export function shareConfig(contentType = 2) {
  const content = {
    imageUrl: 'https://jz.yofish.com/img/logo.png',
    title: '免费领会员',
    desc: '看视频看资讯也能领会员咯~16项会员特权+专业记账体验，限时参与哦！',
    link: 'https://jz.yofish.com/index.html',
  };
  return [
    { content, contentType, platformType: 1 }, // 微信朋友
    { content, contentType, platformType: 2 }, // 微信朋友圈
    { content, contentType, platformType: 4 }, // qq
    { content, contentType, platformType: 0 }, // 微博
    {
      content,
      contentType,
      platformType: 1002, // safari
    },
  ];
}

export function watchVideo() {
  try {
    let { appId, releaseVersion } = getAppStatus();
    if (/android/i.test(navigator.userAgent)) {
      // android
      let random = Math.floor(Math.random() * 10) + 1;
      if (random % 2 === 0) {
        let slotID = '3071906340671206';
        if (appId === 'com.ttjz') slotID = '7011906340275359';
        else if (appId === 'com.zhangben.jz') slotID = '4091702390084130';
        else if (appId === 'com.zgben.jz') slotID = '9081603360872600';
        else if (appId === 'com.jizgj') slotID = '5021307370970623';
        else if (appId === 'com.jyjzb') slotID = '6001002330377413';
        window.android.watchAdVideo('gdt', slotID, 1);
      } else {
        let slotID = '917337233';
        if (appId === 'com.ttjz') slotID = '917341366';
        else if (appId === 'com.koudai') slotID = '917359958';
        else if (appId === 'com.zhangben.jz') slotID = '917342152';
        else if (appId === 'com.zgben.jz') slotID = '917343158';
        else if (appId === 'com.jizgj') slotID = '917366712';
        else if (appId === 'com.jyjzb') slotID = '917357201';
        if (releaseVersion.replace(/[^0-9]*/g, '') >= 488) {
          window.android.watchAdVideo('csj', slotID, 1);
        } else {
          // window.android.watchAdVideo('csj', slotID);
          window.android.watchAdVideo('csj', slotID, 1);
        }
      }
    }
  } catch (error) {
    console.log('调用客户端失败：' + error);
  }
}
