<template>
  <div>
    <div class="result-page"
    :class="{ 'share-page': isShare }" ref="resultEle">
      <div class="result-row" v-if="!isEmpty">
        <img
        class="side-bg"
src="//download-cos.yofish.com/yofish-gongjushiyebu/20221212090418720-result-outside.png" alt="" />
        <div class="statement-head">
          <div class="name"><i class="label"></i><span class="label-value">{{evalText}}</span></div>
          <div class="head-desc">超过了全国
            <span class="bold">{{annualData.percentValue || 0}}%</span> 的鱼宝儿</div>
        </div>
        <div class="statement-content">
          <div class="statement-income">
            <div class="income-desc">2022年总收入</div>
            <div class="income-line">....................</div>
            <div class="income-value">
              <i class="label"></i>
              <span class="label-value">{{annualData.totalIncome || 0}}元</span>
            </div>
          </div>
          <div class="statement-income">
            <div class="income-desc">2022年总支出</div>
            <div class="income-line">....................</div>
            <div class="income-value">
              <i class="label"></i>
              <span class="label-value">{{annualData.totalExpend || 0}}元</span>
            </div>
          </div>
          <div class="statement-top5">
            <p class="top5-value" v-if="consumeList.length">2022年消费类TOP5</p>
            <div class="consume-row">
              <div class="consume-list"
              :class="{ no: index === 2 }"
                v-for="(item, index) in consumeList"
                :key="index">
                <span class="consume-frame"
                  :style="{ backgroundColor: item.color }"></span>
                <p class="consume-name">{{item.name}}</p>
              </div>
            </div>
          </div>
          <div class="statement-desc">
            <p class="keywords-desc">你的2022年关键词</p>
            <div class="keywords-name">
              <i class="label"></i>
              <span class="label-value">{{keywordsText}}</span>
            </div>
          </div>
          <div class="statement-btn" @click="shareAction"
            v-show="!isShare">晒出我的账单</div>
        </div>
        <div class="share-bottom" v-show="isShare">
          <div class="share-row">
            <div class="share-frame">
              <div class="share-qrcode">
                <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20221212111538137-qrcode.png" alt="" />
              </div>
              <p>查看你的有鱼记账之旅</p>
            </div>
            <div class="share-right">
              <div class="share-desc">
                <p>关注公众号<span class="bold">“有鱼记账Pro”</span></p>
                <p>更有好礼等你拿! ! !</p>
              </div>
              <div class="logo">
                <div class="image-logo">
                  <img
          src="//download-cos.yofish.com/yofish-gongjushiyebu/20221212110714718-logo.png" alt="" />
                </div>
                <span>有鱼记账</span>
                <!-- <div class="frame"></div> -->
              </div>
            </div>
          </div>
          <div class="s-line"></div>
        </div>
      </div>
      <div class="result-empty-row" v-if="isEmpty" v-show="!isShare">
        <div class="statement-btn" @click="shareAction1"
          >晒出我的账单</div>
      </div>
    </div>
    <y-loading
    v-show="isUploading"
    :is-loading-txt="true"
loading-image="//download-cos.yofish.com/yofish-gongjushiyebu/20221201095139626-loading-icon.png"
      loading-txt="图片生成中..." />
  </div>
</template>
<script>
import { getAppStatus } from '@/utils/app';
import { userAgentType } from '@/utils';
import YLoading from '@/components/loading';
import { parse } from 'querystring';

export default {
  name: 'AnnualResult',
  components: { YLoading },
  data() {
    return {
      isShare: false,
      canvasUrl: '',
      isUploading: false,
    };
  },
  mounted() {
    document.title = '有鱼记账之旅';
    window.showOriginPage = () => {
      this.isShare = false;
    };
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
    },
    colors() {
      return ['#FF8519', '#FF4C7A', '#504CFF', '#00BAFF', '#1ADD68'];
    },
    annualData() {
      const data = sessionStorage.getItem('annualData');
      return JSON.parse(data || '{}');
    },
    consumeList() {
      let { expendBillTypeTop5s } = this.annualData;
      const { colors } = this;
      let list = [];
      expendBillTypeTop5s = expendBillTypeTop5s || [];
      expendBillTypeTop5s.forEach((item, index) => {
        list.push({
          name: item.key,
          color: colors[index],
        });
      });
      return list;
    },
    evalText() {
      const { totalAccountDay } = this.annualData;
      let text = '勤劳的小蜜蜂';
      if (totalAccountDay >= 1 && totalAccountDay < 100) {
        text = '冉冉升起的记账新星';
      }
      if (totalAccountDay >= 100 && totalAccountDay < 250) {
        text = '细致的记账小能手';
      }
      if (totalAccountDay >= 250 && totalAccountDay < 450) {
        text = '进击的记账勇士';
      }
      if (totalAccountDay >= 450 && totalAccountDay < 600) {
        text = '积极的记账达人';
      }
      if (totalAccountDay >= 600 && totalAccountDay < 800) {
        text = '浪漫的生活家';
      }
      if (totalAccountDay >= 800 && totalAccountDay < 1200) {
        text = '进击的记账勇士';
      }
      return text;
    },
    keywordsText() {
      const { totalExpend } = this.annualData;
      let text = '坚守幸福';
      if (totalExpend > 50000 && totalExpend <= 70000) text = '精打细算';
      if (totalExpend > 70000 && totalExpend <= 100000) text = '精致生活';
      if (totalExpend > 100000) text = '品质生活';
      return text;
    },
    isEmpty() {
      return this.$route.query.isEmpty;
    },
    version() {
      let appInfo = window?.ios?.jzAppInfo() || '{}';
      // appInfo = window?.android?.jzAppInfo() || '{}';
      // console.log(appInfo);
      appInfo = JSON.parse(appInfo);
      let { version } = appInfo;
      // version = parseInt(version, 10);
      // console.log(version);
      if (version) {
        version = version.split('.');
        version = version.join('');
        version = Number(version);
      }
      return version;
    },
  },
  methods: {
    shareAction() {
      let { isPhone, isAndroid } = userAgentType();
      let bH = this.$refs.resultEle.clientHeight;
      let bW = window.innerWidth;
      let startX = 0.1;
      let startY = 0.1;
      let annualData = JSON.parse(sessionStorage.getItem('annualData') || '{}');
      let { version } = this;
      if (version < 519 && isPhone) {
        let { shareImgUrl } = annualData;
        if (!shareImgUrl) {
          this.isShare = true;
          this.$nextTick(() => {
            this.savePhoto();
          });
        }
        if (shareImgUrl) {
          window?.ios?.saveImageToPhotosAlbum(shareImgUrl);
        }
      }
      if (isAndroid) {
        this.isShare = true;
      }
      if (isPhone && version >= 519) {
        this.isShare = true;
      }
      if (isPhone) {
        bW *= 3;
        bH *= 3;
        startX = 0;
        startY = 0;
      }
      let params = {
        callback: 'window.showOriginPage()',
        startX,
        startY,
        width: bW,
        height: bH,
        platformTypes: '[1,2,4,0]',
      };
      // this.isShare = true;
      window.setTimeout(() => {
        this.shareImageAction(params);
      }, 100);
      window?._monitor('track', 'scwdzdan_click', {
        eventName: '晒出我的账单按钮点击',
      });
    },
    shareImageAction(params) {
      const { version } = this;
      let { isAndroid, isPhone } = userAgentType();
      if (isAndroid) {
        window?.android?.shareScreenshot(JSON.stringify(params));
      }
      console.log(isPhone && version >= 519, version);
      if (isPhone && version >= 519) {
        console.log('version---');
        window?.ios?.shareScreenshot(JSON.stringify(params));
      }
    },
    shareAction1() {
      let { isPhone, isAndroid } = userAgentType();
      const { version } = this;
      if (isPhone && version < 519) {
        if (this.isDowload) return false;
        this.isDowload = true;
        window.setTimeout(() => {
          this.isDowload = false;
        }, 1200);
        const imgUrl = 'https://download-cos.yofish.com/yofish-gongjushiyebu/result-bg.jpg';
        return window?.ios?.saveImageToPhotosAlbum(imgUrl);
      }
      if (isAndroid || (isPhone && version >= 519)) {
        this.isShare = true;
        this.shareAction();
      }
      window?._monitor('track', 'scwdzdan_click', {
        eventName: '晒出我的账单按钮点击',
      });
    },
    savePhoto() {
      this.isUploading = true;
      window.html2canvas(this.$refs.resultEle, {
        useCORS: true,
        scrollY: 0,
        scrollX: 0,
        allowTaint: true,
        scale: 2,
      }).then((canvas) => {
        this.isShare = false;
        this.downJpgByCanvas(canvas);
      }).catch((e) => {
        console.log(e);
        this.isUploading = false;
        this.$toast('图片生成失败');
      });
    },
    downJpgByCanvas(canvas) {
      let value = this.canvasToDataURL(canvas);
      let file = this.dataURLToBlob(value);
      // blob.name = '年度账单-' + new Date().getTime() + '.png';
      console.log(file);
      this.fileList = file;
      this.downloadImage();
      let dataUrl = canvas.toDataURL('image/jpg', 1.0);
      this.canvasUrl = dataUrl;
    },
    canvasToDataURL(canvas, format, quality) {
      return canvas.toDataURL(format || 'image/jpg', quality || 1.0);
    },
    dataURLToBlob(dataurl) {
      let arr = dataurl.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let blobData = new Blob([u8arr], { type: mime });
      let file = new File([blobData], `${new Date().getTime()}.png`, { type: mime });
      return file;
    },
    downloadImage() {
      const that = this;
      const { fileList, cuserId } = this;
      let param = new window.FormData();
      param.append('imageFile', fileList);
      $.ajax({
        type: 'post',
        url: '/api/annualStatement/uploadShareImg',
        contentType: false,
        processData: false,
        dataType: 'json',
        timeout: 0,
        async: true,
        headers: {
          cuserId,
        },
        data: param,
        success(data) {
          if (data?.code === 1) {
            that.isUploading = false;
            let { results } = data;
            console.log(data);
            window?.ios?.saveImageToPhotosAlbum(results);
            let annualData = JSON.parse(sessionStorage.getItem('annualData') || '{}');
            annualData.shareImgUrl = results;
            sessionStorage.setItem('annualData', JSON.stringify(annualData));
          } else {
            that.isUploading = false;
            that.$toast({
              content: '图片生成失败',
              duration: 3000,
            });
          }
        },
        error(e) {
          that.isUploading = false;
          that.$toast({
            content: '图片生成失败',
            duration: 3000,
          });
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import './styles/result.scss';
</style>
